import React from 'react';

const Home = () => {
  return (
    <div className="home">
      <header>
      <img className='imageLogo' src='ia.png' alt=""></img>
        <h1>INFINIA</h1>
        <p>The future of AI-driven awareness.</p>
      </header>
    </div>
  );
};

export default Home;