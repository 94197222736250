// src/components/ChatComponent.js

import React, { useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
const apiUrl = process.env.REACT_APP_API_URL;

const ChatComponent = () => {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await axios.post(`${apiUrl}ask`, {
                prompt: input, 
                assistantId: 'peter' 
            });
    
            setResponse(result.data.message); 
        } catch (error) {
            console.error('Error communicating with the API:', error);
            setResponse('Error communicating with the API.');
        }
    };
    
    return (
        <div>
            <h1>Chat with Peter</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Type your message..."
                    required
                />
                <button type="submit">Send</button>
            </form>
            <div>
                <p>{parse(response)}</p>
            </div>
        </div>
    );
};

export default ChatComponent;
